import React, { Fragment, useState, useEffect } from "react";
import "./charts.css";

import { Tween, Timeline } from "react-gsap";

function Circle({ turbineOn, timerValue, critical, sliderValue }) {
  const radarCircleClass = `radarCircle ${critical ? "failed" : ""}`;
  const [screwValue, setScrewValue] = useState(0);

  useEffect(() => {
    if (sliderValue > 0 && sliderValue < 2000) {
      setScrewValue(0 - sliderValue / 75);
    } else if (sliderValue >= 2000) {
      setScrewValue(0 - sliderValue / 50);
    } else if (sliderValue <= -2000) {
      setScrewValue(0 - sliderValue / 50);
    } else if (sliderValue <= 0 && sliderValue > -2000) {
      setScrewValue(0 - sliderValue / 75);
    }
  }, [sliderValue]);

  return (
    <Fragment>
      <Timeline
        repeat={-1}
        playState={turbineOn === false || timerValue === 0 ? "pause" : "play"}
        target={
          <svg
            viewBox="0 0 192 192"
            style={{
              transform: "scale(0.33)"
            }}
          >
            <g className={radarCircleClass}>
              <path d="M96 96L164.5 27.5L192 96L164.736 164.236L96 192L28 163.5L0 96L27.5 27.5L96 96Z" />
            </g>
          </svg>
        }
      >
        <Tween
          to={{
            y: "3px",
            scaleY: 0.38,
            scaleX: 0.38,
            ease: "Linear.easeNone"
          }}
          duration={4}
        />
        <Tween
          to={{
            y: "0px",
            scaleY: 0.42,
            scaleX: 0.42,
            ease: "Linear.easeNone"
          }}
          duration={4}
        />
        <Tween
          to={{
            y: "-3px",
            scaleY: 0.38,
            scaleX: 0.38,
            ease: "Linear.easeNone"
          }}
          duration={4}
        />
        <Tween
          to={{
            y: "0px",
            scaleY: 0.33,
            scaleX: 0.33,
            ease: "Linear.easeNone"
          }}
          duration={4}
        />
      </Timeline>
      <Timeline
        repeat={-1}
        playState={turbineOn === false || timerValue === 0 ? "pause" : "play"}
        target={
          <svg
            viewBox="0 0 192 192"
            style={{
              transform: "scale(0.33)"
            }}
          >
            <g className={radarCircleClass}>
              <path
                d={`M96 ${screwValue}L164.5 27.5L96 96L27.5 27.5L96 ${screwValue}Z`}
              />
            </g>
          </svg>
        }
      >
        <Tween
          to={{
            y: "3px",
            scaleY: 0.38,
            scaleX: 0.38,
            ease: "Linear.easeNone"
          }}
          duration={4}
        />
        <Tween
          to={{
            y: "0px",
            scaleY: 0.42,
            scaleX: 0.42,
            ease: "Linear.easeNone"
          }}
          duration={4}
        />
        <Tween
          to={{
            y: "-3px",
            scaleY: 0.38,
            scaleX: 0.38,
            ease: "Linear.easeNone"
          }}
          duration={4}
        />
        <Tween
          to={{
            y: "0px",
            scaleY: 0.33,
            scaleX: 0.33,
            ease: "Linear.easeNone"
          }}
          duration={4}
        />
      </Timeline>
    </Fragment>
  );
}

function RadarChart({ turbineOn, timerValue, critical, sliderValue }) {
  return (
    <div className="bladeforces">
      <div className="bladeforces__title">Forces on a wing</div>
      <div className="bladeforces__flex">
        <div className="bladeforces__turbine"></div>
        <div className="bladeforces__radar">
          <div className="bladeforces__radar--width">
            <Circle
              turbineOn={turbineOn}
              timerValue={timerValue}
              critical={critical}
              sliderValue={sliderValue}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(RadarChart);
