import React from "react";
import "./screwstatus.css";

const ScrewStatus = ({
  sliderValue,
  turbineOn,
  timerValue,
  onClickChange,
  criticalValue
}) => {
  const handleChange = () => {
    onClickChange();
  };

  const status =
    "status " +
    (sliderValue >= criticalValue || sliderValue <= -criticalValue
      ? "status--critical"
      : null);

  const status_description =
    timerValue === 0
      ? "Broken"
      : sliderValue >= criticalValue || sliderValue <= -criticalValue
      ? "Critical"
      : "Normal";

  const empty_stop_start =
    timerValue === 0 ? (
      <div className="status__btn--empty"></div>
    ) : turbineOn === true &&
      (sliderValue >= criticalValue || sliderValue <= -criticalValue) ? (
      <button
        className="status__btn status__btn--critical"
        onClick={handleChange}
      >
        Stop machine
      </button>
    ) : turbineOn === false ? (
      <button
        className="status__btn status__btn--critical"
        onClick={handleChange}
      >
        Start machine
      </button>
    ) : (
      <div className="status__btn--empty"></div>
    );

  return (
    <div className={status}>
      <div className="status--margin">
        <div className="status__title">Screw status:</div>
        <div className="status__description">{status_description}</div>
        <div>{empty_stop_start}</div>
      </div>
      <div className="phone__btn">
        <svg viewBox="0 0 512 53" fill="none">
          <g stroke="#000">
            <path
              opacity=".6"
              d="M256 50c-12 0-23-11-23-24s11-23 23-23c13 0 24 10 24 23s-11 24-24 24zM468 5h41v42h-41V5zM3 5h42v42H3V5z"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default React.memo(ScrewStatus);
