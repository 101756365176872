import React, { useEffect, useState, Fragment } from "react";
import "./charts.css";
import { Tween, Timeline } from "react-gsap";

function Line({ turbineOn, timerValue, sliderValue, critical }) {
  const lineClass = `line--stroke ${critical ? "failed" : ""}`;
  const [amplitude, setAmplitude] = useState(30);
  const [amplitudeLow, setAmplitudeLow] = useState(108);

  useEffect(() => {
    setAmplitude(30 - sliderValue / 160);
    setAmplitudeLow(108 + sliderValue / 100);
  }, [sliderValue]);

  return (
    <Fragment>
      <Timeline
        repeat={-1}
        playState={turbineOn === false || timerValue === 0 ? "pause" : "play"}
        wrapper={<svg viewBox="0 0 1096 112" fill="none"></svg>}
        target={
          <path
            d={`M183.366 ${amplitudeLow}C250.676 ${amplitudeLow} 224.065 ${amplitude} 276.505 ${amplitude}C328.944 ${amplitude} 299.985 ${amplitudeLow} 365.73 ${amplitudeLow}C433.04 ${amplitudeLow} 406.429 ${amplitude} 458.869 ${amplitude}C511.308 ${amplitude} 482.349 ${amplitudeLow} 548.094 ${amplitudeLow}C615.404 ${amplitudeLow} 588.793 ${amplitude} 641.233 ${amplitude}C693.672 ${amplitude} 664.713 ${amplitudeLow} 730.458 ${amplitudeLow}C797.768 ${amplitudeLow} 771.157 ${amplitude} 823.597 ${amplitude}C876.036 ${amplitude} 847.077 ${amplitudeLow} 912.822 ${amplitudeLow}C980.132 ${amplitudeLow} 953.521 ${amplitude} 1005.96 ${amplitude}C1058.4 ${amplitude} 1029.44 ${amplitudeLow} 1095.19 ${amplitudeLow}M183.364 ${amplitudeLow}C117.619 ${amplitudeLow} 146.578 ${amplitude} 94.1386 ${amplitude}C41.6992 ${amplitude} 68.3104 ${amplitudeLow} 1.00009 ${amplitudeLow}`}
            className={lineClass}
          />
        }
      >
        <Tween
          duration={16}
          from={{
            x: 0,
            y: -50,
            scaleY: 1.5,
            ease: "Linear.easeNone"
          }}
          to={{
            x: -182,
            y: -50,
            scaleY: 1.5,
            ease: "Linear.easeNone"
          }}
        ></Tween>
      </Timeline>
    </Fragment>
  );
}

function LineChart({ sliderValue, turbineOn, timerValue, critical }) {
  return (
    <div className="line">
      <div className="line__title">Forces on a screw</div>
      <div className="line__chart">
        <Line
          amplitude={sliderValue}
          sliderValue={sliderValue}
          turbineOn={turbineOn}
          timerValue={timerValue}
          critical={critical}
        />
      </div>
    </div>
  );
}

export default LineChart;
