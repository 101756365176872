import React from "react";
import "./counter.css";

const Countdown = ({ sliderValue, turbineOn, timerValue, criticalValue }) => {
  return (
    <div className="countdown-wrapper">
      {timerValue === 0 ? (
        <div className="countdown countdown--broken">
          <span>You lost € 250 000</span>
        </div>
      ) : turbineOn === true &&
        (sliderValue >= criticalValue || sliderValue <= -criticalValue) ? (
        <div className="countdown countdown--broken">
          Machine will be broken in {timerValue} days
        </div>
      ) : turbineOn === false ? (
        <div className="countdown countdown--win">You saved € 250 000</div>
      ) : (
        <div className="countdown"></div>
      )}
    </div>
  );
};

export default React.memo(Countdown);
