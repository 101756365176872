import React, { PureComponent } from "react";
import "./animation.css";

class Cloud extends PureComponent {
  render() {
    return (
      <div className="wrapper__cloud">
        <div className="cloud cloud1">
          <svg
            fill="none"
            viewBox="0 0 857 341"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m738.4 341h-642.4c-104.1 0-132.6-143.6-36.2-182.9 1.3-0.5 2.5999-1.1 3.8999-1.6 79-27.1 141.4 33.5 141.4 33.5s8-187.8 195-189c195.3-1.2 214.6 164 214.6 164s66.3-86.8 161.5-54.2c2.1 0.7 4.1 1.5 6.1 2.2 121.4 47.1 86.3 228-43.9 228z"
              fill="#fff"
              opacity=".4"
            />
          </svg>
        </div>
        <div className="cloud cloud2">
          <svg
            fill="none"
            viewBox="0 0 925 420"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m80.226 419.16h766c31.9 0 61.2-18.8 73.3-48.3 7.3-17.7 8.5-39.4-6.2-63.2-43.5-70.3-134-31.8-134-31.8s23.4-123.9-102.1-135.6c-85.8-8-113 69.6-113 69.6s-1.6-257.9-246.7-201.9c-219.6 50.2-157 269.1-157 269.1s-55.9-44.8-121.2-6.3c-1.3 0.7-2.5 1.5-3.7 2.3-65.9 43.3-34.2 146.1 44.6 146.1z"
              fill="#fff"
              opacity=".4"
            />
          </svg>
        </div>
        <div className="cloud cloud3">
          <svg
            fill="none"
            viewBox="0 0 1178 534"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m102.42 533.54h974.7c40.6 0 77.9-23.9 93.3-61.5 9.3-22.6 10.9-50.1-7.9-80.5-55.4-89.5-170.4-40.5-170.4-40.5s29.805-157.7-130-172.6c-109.2-10.2-143.7 88.6-143.7 88.6s-2.1-328.2-314-256.9c-279.5 64-199.8 342.6-199.8 342.6s-71.1-57-154.2-8c-1.6 1-3.2 1.9-4.7 2.9-83.9 55-43.6 185.9 56.7 185.9z"
              fill="#fff"
              opacity=".4"
            />
          </svg>
        </div>
        <div className="cloud cloud4">
          <svg
            fill="none"
            viewBox="0 0 857 341"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m738.4 341h-642.4c-104.1 0-132.6-143.6-36.2-182.9 1.3-0.5 2.5999-1.1 3.8999-1.6 79-27.1 141.4 33.5 141.4 33.5s8-187.8 195-189c195.3-1.2 214.6 164 214.6 164s66.3-86.8 161.5-54.2c2.1 0.7 4.1 1.5 6.1 2.2 121.4 47.1 86.3 228-43.9 228z"
              fill="#fff"
              opacity=".4"
            />
          </svg>
        </div>
        <div className="cloud cloud5">
          <svg
            fill="none"
            viewBox="0 0 857 341"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m738.4 341h-642.4c-104.1 0-132.6-143.6-36.2-182.9 1.3-0.5 2.5999-1.1 3.8999-1.6 79-27.1 141.4 33.5 141.4 33.5s8-187.8 195-189c195.3-1.2 214.6 164 214.6 164s66.3-86.8 161.5-54.2c2.1 0.7 4.1 1.5 6.1 2.2 121.4 47.1 86.3 228-43.9 228z"
              fill="#fff"
              opacity=".4"
            />
          </svg>
        </div>
      </div>
    );
  }
}

export default Cloud;
