import React, { PureComponent } from "react";
import "./animation.css";

class Ufo extends PureComponent {
  render() {
    return (
      <div className="wrapper__ufo">
        <svg viewBox="0 0 413 150" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m412.7 79.2c0-1.1-1.1-1.8-2.2-1.8h-3.7c-21.6-25.7-103-44.8-200.2-44.8s-178.6 19.1-200.2 44.8h-3.6c-1.1 0-2 0.9-2 2v11.6c0 32.2 92.1 58.3 205.8 58.3 113.7 0 205.8-26.1 205.8-58.3 0-0.2 0.3-11.8 0.3-11.8z"
            fill="#4D4D4D"
          />
          <path
            d="m206.6 134.3c113.66 0 205.8-26.102 205.8-58.3 0-32.198-92.14-58.3-205.8-58.3-113.66 0-205.8 26.102-205.8 58.3 0 32.198 92.14 58.3 205.8 58.3z"
            fill="#5EBCFC"
          />
          <path
            d="m206.6 58.9c58.708 0 106.3-13.118 106.3-29.3 0-16.182-47.592-29.3-106.3-29.3s-106.3 13.118-106.3 29.3c0 16.182 47.592 29.3 106.3 29.3z"
            fill="#4D4D4D"
          />
          <path
            d="m207.6 117.6c17.121 0 31-7.745 31-17.3 0-9.5545-13.879-17.3-31-17.3s-31 7.7455-31 17.3c0 9.555 13.879 17.3 31 17.3z"
            fill="#FC892B"
          />
          <path
            d="m90.859 83.44c2.5606-8.3993-6.1553-18.498-19.467-22.557-13.312-4.0583-26.18-0.5394-28.74 7.86-2.5606 8.3993 6.1552 18.498 19.467 22.557 13.312 4.0583 26.18 0.5393 28.74-7.8601z"
            fill="#FC892B"
          />
          <path
            d="m355.63 93.597c13.559-4.5588 22.125-15.469 19.132-24.369-2.992-8.8997-16.409-12.419-29.968-7.86-13.559 4.5587-22.125 15.469-19.133 24.369 2.992 8.8997 16.41 12.419 29.969 7.86z"
            fill="#FC892B"
          />
        </svg>
      </div>
    );
  }
}

export default Ufo;
