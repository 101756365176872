import React, { Component } from "react";
import "./animation.css";

class Logo extends Component {
  render() {
    return (
      <a href="https://www.sensorise.de">
        <div className="logo">
          <svg viewBox="0 0 358 135" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m24 122.3c0-0.6-0.2-1-0.5-1.4-0.4-0.4-1-0.7-1.9-1s-2.1-0.6-3.5-0.9c-1.5-0.3-3.4-0.7-5.6-1.2s-4.1-1.1-5.6-1.9-2.7-1.6-3.5-2.7c-0.9-1-1.5-2.2-1.9-3.5s-0.5-2.7-0.5-4.2 0.4-3 1.1-4.5c0.7-1.4998 1.7-2.7998 3.1-3.8998 1.3-1.1 3-2.0999 4.9-2.7999 1.9-0.7 4.1-1.1001 6.6-1.1001s4.7 0.3 6.7 0.8c2 0.6 3.6 1.3 5 2.2s2.4 2.0001 3.2 3.2001c0.8 1.1997 1.2 2.3997 1.3 3.5997 0 0.6-0.1 1-0.6 1.4-0.4 0.4-0.9 0.6-1.5 0.6h-5.9c-0.8 0-1.3-0.1-1.7-0.4s-0.8-0.6-1.3-1-1.1-0.7-1.8-1-1.9-0.4-3.4-0.4-2.9 0.2-4 0.7c-1.2 0.5-1.8 1.3-1.8 2.4 0 0.6 0.1 1 0.4 1.4s0.8 0.7 1.6 1 1.9 0.6 3.3 1c1.4 0.3 3.2 0.7 5.4 1.2 4.5 1 7.7 2.5 9.6 4.5 1.9 2.1 2.8 4.6 2.8 7.7 0 1.5-0.4 3-1.1 4.5-0.8 1.4-1.9 2.7-3.3 3.9-1.4 1.1-3.2 2.1-5.3 2.7-2.1 0.7-4.5 1-7.1 1-2.7 0-5.1-0.3-7.1-1-2.1-0.6-3.8-1.5-5.2-2.5s-2.5-2.2-3.3-3.4c-0.8-1.3-1.2-2.5-1.2-3.7-0.1-0.6 0.099999-1 0.6-1.4 0.4-0.4 0.9-0.6 1.5-0.6h5.9c0.8 0 1.3 0.2 1.7 0.5 0.4 0.4 0.8 0.8 1.3 1.2 0.5 0.5 1.2 0.9 2 1.2 0.9 0.4 2.2 0.5 3.9 0.5 0.8 0 1.5 0 2.4-0.2 0.8-0.1 1.6-0.3 2.2-0.5 0.7-0.2 1.3-0.5 1.7-0.9 0.2-0.1 0.4-0.5 0.4-1.1z"
              fill="#fff"
            />
            <path
              d="m41.7 113.9c0-2.8 0.4-5.5 1.3-8s2.1-4.7 3.7-6.5001c1.6-1.9 3.6-3.3 5.9-4.4s4.9-1.6 7.7-1.6 5.4 0.5 7.7 1.6c2.3 1 4.2999 2.3999 5.8999 4.1999 1.6 1.8002 2.9001 3.8002 3.8001 6.2002s1.3 4.8 1.3 7.4v3.4c0 0.6-0.2001 1-0.6001 1.4s-0.8999 0.6-1.3999 0.6h-25.4c0 1.2 0.2 2.2 0.7 3.1s1.1 1.6 1.9 2.2 1.8 1.1 2.8 1.4c1.1 0.3 2.2 0.5 3.3 0.5 1.6 0 2.9-0.2 4-0.5 1-0.3 1.9-0.7 2.5-1.3 0.6-0.4 1-0.7 1.4-0.8 0.4-0.2 0.8-0.2 1.4-0.2h6.1c0.6 0 1.1 0.2 1.5 0.6s0.6 0.9 0.6 1.4c-0.1 0.7-0.4 1.6-1.2 2.7-0.7 1.1-1.8 2.2-3.3 3.3s-3.3001 2-5.4001 2.7c-2.2 0.8-4.7 1.1-7.5 1.1s-5.3999-0.5-7.6999-1.5-4.3-2.4-5.9-4.2-2.9-4-3.7-6.5c-1-2.5-1.4-5.3-1.4-8.3zm18.7-11.5c-1.5 0-2.6999 0.2-3.7999 0.6-1 0.4-1.9 1-2.6 1.7s-1.3001 1.4-1.6001 2.3c-0.4 0.8-0.5999 1.6-0.6999 2.4h17.2c-0.1-0.8-0.2999-1.6-0.5999-2.4s-0.7001-1.6-1.4001-2.3c-0.6-0.7-1.5-1.2-2.5-1.7-1.1-0.3-2.4-0.6-4-0.6z"
              fill="#fff"
            />
            <path
              d="m159.4 122.3c0-0.6-0.2-1-0.5-1.4-0.4-0.4-1-0.7-1.9-1s-2.1-0.6-3.5-0.9c-1.5-0.3-3.3-0.7-5.6-1.2-2.2-0.5-4.1-1.1-5.6-1.9s-2.7-1.6-3.5-2.7c-0.9-1-1.5-2.2-1.9-3.5s-0.5-2.7-0.5-4.2 0.4-3 1.1-4.5c0.7-1.4998 1.7-2.7998 3.1-3.8998 1.3-1.1 3-2.0999 4.9-2.7999s4.1-1.1001 6.6-1.1001 4.7 0.3 6.7 0.8c2 0.6 3.6 1.3 5 2.2s2.4 2.0001 3.2 3.2001c0.8 1.1997 1.2 2.3997 1.3 3.5997 0 0.6-0.1 1-0.6 1.4-0.4 0.4-0.9 0.6-1.5 0.6h-5.9c-0.8 0-1.3-0.1-1.7-0.4s-0.8-0.6-1.3-1-1.1-0.7-1.8-1c-0.8-0.3-1.9-0.4-3.4-0.4s-2.9 0.2-4 0.7c-1.2 0.5-1.8 1.3-1.8 2.4 0 0.6 0.1 1 0.4 1.4s0.8 0.7 1.6 1 1.9 0.6 3.3 1c1.4 0.3 3.2 0.7 5.4 1.2 4.5 1 7.7 2.5 9.6 4.5 1.9 2.1 2.8 4.6 2.8 7.7 0 1.5-0.4 3-1.1 4.5-0.8 1.4-1.9 2.7-3.3 3.9-1.4 1.1-3.2 2.1-5.3 2.7-2.1 0.7-4.5 1-7.1 1-2.7 0-5.1-0.3-7.1-1-2.1-0.6-3.8-1.5-5.2-2.5s-2.5-2.2-3.3-3.4c-0.8-1.3-1.2-2.5-1.2-3.7 0-0.6 0.1-1 0.6-1.4s0.9-0.6 1.5-0.6h5.9c0.8 0 1.3 0.2 1.7 0.5 0.4 0.4 0.8 0.8 1.3 1.2 0.5 0.5 1.2 0.9 2 1.2 0.9 0.4 2.2 0.5 3.9 0.5 0.8 0 1.5 0 2.4-0.2 0.8-0.1 1.6-0.3 2.2-0.5 0.7-0.2 1.3-0.5 1.7-0.9 0.2-0.1 0.4-0.5 0.4-1.1z"
              fill="#fff"
            />
            <path
              d="m196.5 93.3c2.8 0 5.3 0.3999 7.6 1.2999s4.2 2.1 5.9 3.6c1.6 1.5 3 3.3003 4 5.3003s1.6 4.2 1.7 6.5c0 0.5 0.1 1 0.1 1.8v4.2s0 1.3-0.1 1.7c-0.2 2.3-0.8 4.5-1.8 6.5s-2.3 3.8-3.9 5.3c-1.7 1.5-3.6 2.7-5.9 3.5-2.3 0.9-4.8 1.3-7.6 1.3s-5.3-0.4-7.6-1.3-4.2-2-5.9-3.5c-1.6-1.5-3-3.3-3.9-5.3-1-2-1.5-4.2-1.8-6.5-0.1-0.5-0.1-1-0.1-1.7v-2.1-2.1c0-0.7 0.1-1.3 0.1-1.8 0.2-2.3 0.7-4.5 1.7-6.5s2.3-3.8003 4-5.3003c1.6-1.5 3.6-2.7 5.9-3.6s4.8-1.2999 7.6-1.2999zm9.2 17.1c-0.2-1.6-0.5-2.9-1.1-3.9s-1.3-1.9-2.2-2.5-1.8-1-2.8-1.3c-1-0.2-2.1-0.3-3.1-0.3-1.1 0-2.1 0.1-3.1 0.3s-2 0.6-2.8 1.3c-0.9 0.6-1.6 1.4-2.2 2.5-0.6 1-1 2.3-1.1 3.9-0.1 0.4-0.1 0.9-0.1 1.5v3.8c0 0.6 0.1 1.1 0.1 1.5 0.2 1.6 0.5 2.9 1.1 3.9s1.3 1.9 2.2 2.5 1.8 1 2.8 1.3c1 0.2 2.1 0.3 3.1 0.3 1.1 0 2.1-0.1 3.1-0.3s2-0.6 2.8-1.3c0.9-0.6 1.6-1.4 2.2-2.5 0.6-1 1-2.3 1.1-3.9 0-0.4 0.1-0.8 0.1-1.5v-1.9-1.9c0-0.6 0-1.1-0.1-1.5z"
              fill="#fff"
            />
            <path
              d="m249.3 94.7c-0.4-0.4-0.9-0.6-1.4-0.6h-3.4-6.2-1.1c-4.4 0-8.2 2.6-10 6.2999-0.3 0.5-0.5 1-0.6 1.5-0.3 1.1-0.5 2.2-0.5 3.4v26.4c0 0.6 0.2 1 0.6 1.4s0.9 0.6 1.4 0.6h5.8c0.6 0 1-0.2 1.4-0.6s0.6-0.9 0.6-1.4v-19.8c0-3 0.5-5.4 1.8-6.7s2.2-1.9 5.2-1.9h4.9c0.6 0 1-0.2 1.4-0.6s0.6-0.9 0.6-1.4v-4.9998c0.1-0.7-0.1-1.2001-0.5-1.6001z"
              fill="#fff"
            />
            <path
              d="m269.3 131.6c0 0.6-0.2 1-0.6 1.4s-0.9 0.6-1.4 0.6h-5.8c-0.6 0-1-0.2-1.4-0.6s-0.6-0.9-0.6-1.4v-35.5c0-0.6 0.2-1 0.6-1.4s0.9-0.6 1.4-0.6h5.8c0.6 0 1 0.2 1.4 0.6s0.6 0.9 0.6 1.4v35.5z"
              fill="#fff"
            />
            <path
              d="m303 122.3c0-0.6-0.2-1-0.5-1.4-0.4-0.4-1-0.7-1.9-1s-2.1-0.6-3.5-0.9c-1.5-0.3-3.4-0.7-5.6-1.2s-4.1-1.1-5.6-1.9-2.7-1.6-3.5-2.7c-0.9-1-1.5-2.2-1.9-3.5s-0.5-2.7-0.5-4.2 0.4-3 1.1-4.5c0.7-1.4998 1.7-2.7998 3.1-3.8998 1.3-1.1 3-2.0999 4.9-2.7999s4.1-1.1001 6.6-1.1001 4.7 0.3 6.7 0.8c2 0.6 3.6 1.3 5 2.2s2.4 2.0001 3.2 3.2001c0.8 1.1997 1.2 2.3997 1.3 3.5997 0.1 0.6-0.1 1-0.6 1.4-0.4 0.4-0.9 0.6-1.5 0.6h-5.9c-0.8 0-1.3-0.1-1.7-0.4s-0.8-0.6-1.3-1-1.1-0.7-1.8-1c-0.8-0.3-1.9-0.4-3.4-0.4s-2.9 0.2-4 0.7c-1.2 0.5-1.8 1.3-1.8 2.4 0 0.6 0.1 1 0.4 1.4s0.8 0.7 1.6 1 1.9 0.6 3.3 1c1.4 0.3 3.2 0.7 5.4 1.2 4.5 1 7.7 2.5 9.6 4.5 1.9 2.1 2.8 4.6 2.8 7.7 0 1.5-0.4 3-1.1 4.5-0.8 1.4-1.9 2.7-3.3 3.9-1.4 1.1-3.2 2.1-5.3 2.7-2.1 0.7-4.5 1-7.1 1-2.7 0-5.1-0.3-7.1-1-2.1-0.6-3.8-1.5-5.2-2.5s-2.5-2.2-3.3-3.4c-0.8-1.3-1.2-2.5-1.2-3.7-0.1-0.6 0.1-1 0.6-1.4 0.4-0.4 0.9-0.6 1.5-0.6h5.9c0.8 0 1.3 0.2 1.7 0.5 0.4 0.4 0.8 0.8 1.3 1.2 0.5 0.5 1.2 0.9 2 1.2 0.9 0.4 2.2 0.5 3.9 0.5 0.8 0 1.5 0 2.4-0.2 0.8-0.1 1.6-0.3 2.2-0.5 0.7-0.2 1.3-0.5 1.7-0.9 0.2-0.1 0.4-0.5 0.4-1.1z"
              fill="#fff"
            />
            <path
              d="m320.7 113.9c0-2.8 0.4-5.5 1.3-8s2.1-4.7 3.7-6.5001c1.6-1.9 3.6-3.3 5.9-4.4s4.9-1.6 7.7-1.6 5.4 0.5 7.7 1.6c2.3 1 4.3 2.3999 5.9 4.1999 1.6 1.8002 2.9 3.8002 3.8 6.2002s1.3 4.8 1.3 7.4v3.4c0 0.6-0.2 1-0.6 1.4s-0.9 0.6-1.4 0.6h-25.4c0 1.2 0.2 2.2 0.7 3.1s1.1 1.6 1.9 2.2 1.8 1.1 2.8 1.4c1.1 0.3 2.2 0.5 3.3 0.5 1.6 0 2.9-0.2 4-0.5 1-0.3 1.9-0.7 2.5-1.3 0.6-0.4 1-0.7 1.4-0.8 0.4-0.2 0.8-0.2 1.4-0.2h6.1c0.6 0 1.1 0.2 1.5 0.6s0.6 0.9 0.6 1.4c-0.1 0.7-0.4 1.6-1.2 2.7-0.7 1.1-1.8 2.2-3.3 3.3s-3.3 2-5.4 2.7c-2.2 0.8-4.7 1.1-7.5 1.1s-5.4-0.5-7.7-1.5-4.3-2.4-5.9-4.2-2.9-4-3.7-6.5c-1-2.5-1.4-5.3-1.4-8.3zm18.6-11.5c-1.5 0-2.7 0.2-3.8 0.6-1 0.4-1.9 1-2.6 1.7s-1.3 1.4-1.6 2.3c-0.4 0.8-0.6 1.6-0.7 2.4h17.2c-0.1-0.8-0.3-1.6-0.6-2.4s-0.7-1.6-1.4-2.3c-0.6-0.7-1.5-1.2-2.5-1.7-1-0.3-2.4-0.6-4-0.6z"
              fill="#fff"
            />
            <path
              d="m125.4 104.3c-0.5-2.2-1.4-4-2.7-5.7003-1.3-1.6-2.9-2.9-5-3.9s-4.7-1.3999-7.8-1.3999h-2.3-2.3c-3.1 0-5.7 0.4999-7.8 1.3999-2.1 1-3.8 2.3-5 3.9-1.3 1.6003-2.2 3.5003-2.7 5.7003s-0.8 4.4-0.8 6.8v20.5c0 0.6 0.2 1 0.6 1.4s0.9 0.6 1.4 0.6h5.8c0.5 0 0.9-0.2 1.2-0.4l0.2-0.2c0.1-0.1 0.1-0.1 0.1-0.2 0.3-0.4 0.5-0.8 0.5-1.3v-19.3c0-3 0.8-5.5 2.3-7.3s3.7-2.7 6.4-2.7h0.2c2.7 0 4.8 0.9 6.4 2.7 1.5 1.8 2.3 4.3 2.3 7.3v19.3c0 0.5 0.2 0.9 0.5 1.3 0 0.1 0.1 0.1 0.1 0.2l0.2 0.2c0.4 0.3 0.8 0.4 1.2 0.4h5.8c0.6 0 1-0.2 1.4-0.6s0.6-0.9 0.6-1.4v-20.5c0-2.4-0.3-4.6-0.8-6.8z"
              fill="#fff"
            />
            <path
              d="m115.9 34.1c-3.1 0-5.5 0.3-5.5 3.3v8.7c0-3 2.4-3.2999 5.5-3.2999s5.5 0.2999 5.5 3.2999v-8.7c0-3.1-2.4-3.3-5.5-3.3z"
              fill="#fff"
              opacity=".3"
            />
            <path
              d="m115.9 42.7c-3.1 0-5.5 0.3-5.5 3.3v12.6c0-3 2.4-3.2999 5.5-3.2999s5.5 0.2999 5.5 3.2999v-12.6c0-3-2.4-3.3-5.5-3.3z"
              fill="#fff"
              opacity=".7"
            />
            <path
              d="m115.9 55.3c-3.1 0-5.5 0.2999-5.5 3.2999v14.4c0 3 2.4 3.3 5.5 3.3s5.5-0.3 5.5-3.3v-14.4c0-3.1-2.4-3.2999-5.5-3.2999z"
              fill="#fff"
            />
            <path
              d="m180.5 24.8c-3.1 0-5.5 0.2999-5.5 3.2999v9.1c0-3 2.4-3.2999 5.5-3.2999s5.5 0.2999 5.5 3.2999v-9.1c0-3-2.4-3.2999-5.5-3.2999z"
              fill="#fff"
              opacity=".3"
            />
            <path
              d="m180.5 33.9c-3.1 0-5.5 0.2999-5.5 3.2999v12.6c0-3 2.4-3.3 5.5-3.3s5.5 0.3 5.5 3.3v-12.6c0-3-2.4-3.2999-5.5-3.2999z"
              fill="#fff"
              opacity=".7"
            />
            <path
              d="m180.5 46.5c-3.1 0-5.5 0.3-5.5 3.3v14.4c0 3 2.4 3.3 5.5 3.3s5.5-0.3 5.5-3.3v-14.4c0-3-2.4-3.3-5.5-3.3z"
              fill="#fff"
            />
            <path
              d="m148.2 23c-3.1 0-5.5 0.3-5.5 3.3v8.5c0-3 2.4-3.3 5.5-3.3s5.5 0.3 5.5 3.3v-8.5c0-3-2.4-3.3-5.5-3.3z"
              fill="#fff"
              opacity=".3"
            />
            <path
              d="m148.2 31.5c-3.1 0-5.5 0.3-5.5 3.3v12.6c0-3 2.4-3.3 5.5-3.3s5.5 0.3 5.5 3.3v-12.6c0-3-2.4-3.3-5.5-3.3z"
              fill="#fff"
              opacity=".7"
            />
            <path
              d="m148.2 44.1c-3.1 0-5.5 0.3-5.5 3.3v19c0 3 2.4 3.3 5.5 3.3s5.5-0.3 5.5-3.3v-19c0-3.1-2.4-3.3-5.5-3.3z"
              fill="#fff"
            />
            <path
              d="m164.2 0.5c-3.1 0-5.5 0.30005-5.5 3.3v8.4c0-3 2.4-3.3 5.5-3.3s5.5 0.29993 5.5 3.3v-8.4c0-3.1-2.4-3.3-5.5-3.3z"
              fill="#fff"
              opacity=".3"
            />
            <path
              d="m164.2 8.8999c-3.1 0-5.5 0.29993-5.5 3.2999v12.6c0-3 2.4-3.3 5.5-3.3s5.5 0.3 5.5 3.3v-12.6c0-3.1-2.4-3.2999-5.5-3.2999z"
              fill="#fff"
              opacity=".7"
            />
            <path
              d="m164.2 21.4c-3.1 0-5.5 0.2999-5.5 3.2999v40.5c0 3 2.4 3.3001 5.5 3.3001s5.5-0.3001 5.5-3.3001v-40.5c0-3-2.4-3.2999-5.5-3.2999z"
              fill="#fff"
            />
            <path
              d="m132.1 16.2c-3.1 0-5.5 0.3-5.5 3.3v8.6c0-3 2.4-3.2999 5.5-3.2999s5.5 0.2999 5.5 3.2999v-8.6c0-3.1-2.5-3.3-5.5-3.3z"
              fill="#fff"
              opacity=".3"
            />
            <path
              d="m132.1 24.8c-3.1 0-5.5 0.2999-5.5 3.2999v12.6c0-3 2.4-3.2999 5.5-3.2999s5.5 0.2999 5.5 3.2999v-12.6c0-3-2.5-3.2999-5.5-3.2999z"
              fill="#fff"
              opacity=".7"
            />
            <path
              d="m132.1 37.4c-3.1 0-5.5 0.2999-5.5 3.2999v28c0 3 2.4 3.3001 5.5 3.3001s5.5-0.3001 5.5-3.3001v-28c0-3-2.5-3.2999-5.5-3.2999z"
              fill="#fff"
            />
            <path
              d="m245.1 34.1c3.1 0 5.5 0.3 5.5 3.3v8.7c0-3-2.4-3.2999-5.5-3.2999s-5.5 0.2999-5.5 3.2999v-8.7c0-3.1 2.5-3.3 5.5-3.3z"
              fill="#fff"
              opacity=".3"
            />
            <path
              d="m245.1 42.7c3.1 0 5.5 0.3 5.5 3.3v12.6c0-3-2.4-3.2999-5.5-3.2999s-5.5 0.2999-5.5 3.2999v-12.6c0-3 2.5-3.3 5.5-3.3z"
              fill="#fff"
              opacity=".7"
            />
            <path
              d="m245.1 55.3c3.1 0 5.5 0.2999 5.5 3.2999v14.4c0 3-2.4 3.3-5.5 3.3s-5.5-0.3-5.5-3.3v-14.4c0-3.1 2.5-3.2999 5.5-3.2999z"
              fill="#fff"
            />
            <path
              d="m212.8 23c3.1 0 5.5 0.3 5.5 3.3v8.5c0-3-2.4-3.3-5.5-3.3s-5.5 0.3-5.5 3.3v-8.5c0-3 2.5-3.3 5.5-3.3z"
              fill="#fff"
              opacity=".3"
            />
            <path
              d="m212.8 31.5c3.1 0 5.5 0.3 5.5 3.3v12.6c0-3-2.4-3.3-5.5-3.3s-5.5 0.3-5.5 3.3v-12.6c0-3 2.5-3.3 5.5-3.3z"
              fill="#fff"
              opacity=".7"
            />
            <path
              d="m212.8 44.1c3.1 0 5.5 0.3 5.5 3.3v19c0 3-2.4 3.3-5.5 3.3s-5.5-0.3-5.5-3.3v-19c0-3.1 2.5-3.3 5.5-3.3z"
              fill="#fff"
            />
            <path
              d="m196.9 0.5c3.1 0 5.5 0.30005 5.5 3.3v8.4c0-3-2.4-3.3-5.5-3.3s-5.5 0.29993-5.5 3.3v-8.4c-0.1-3.1 2.4-3.3 5.5-3.3z"
              fill="#fff"
              opacity=".3"
            />
            <path
              d="m196.9 8.8999c3.1 0 5.5 0.29993 5.5 3.2999v12.6c0-3-2.4-3.3-5.5-3.3s-5.5 0.3-5.5 3.3v-12.6c-0.1-3.1 2.4-3.2999 5.5-3.2999z"
              fill="#fff"
              opacity=".7"
            />
            <path
              d="m196.9 21.4c3.1 0 5.5 0.2999 5.5 3.2999v40.5c0 3-2.4 3.3001-5.5 3.3001s-5.5-0.3001-5.5-3.3001v-40.5c-0.1-3 2.4-3.2999 5.5-3.2999z"
              fill="#fff"
            />
            <path
              d="m229 16.2c3.1 0 5.5 0.3 5.5 3.3v8.6c0-3-2.4-3.2999-5.5-3.2999s-5.5 0.2999-5.5 3.2999v-8.6c0-3.1 2.4-3.3 5.5-3.3z"
              fill="#fff"
              opacity=".3"
            />
            <path
              d="m229 24.8c3.1 0 5.5 0.2999 5.5 3.2999v12.6c0-3-2.4-3.2999-5.5-3.2999s-5.5 0.2999-5.5 3.2999v-12.6c0-3 2.4-3.2999 5.5-3.2999z"
              fill="#fff"
              opacity=".7"
            />
            <path
              d="m229 37.4c3.1 0 5.5 0.2999 5.5 3.2999v28c0 3-2.4 3.3001-5.5 3.3001s-5.5-0.3001-5.5-3.3001v-28c0-3 2.4-3.2999 5.5-3.2999z"
              fill="#fff"
            />
          </svg>
        </div>
      </a>
    );
  }
}

export default Logo;
