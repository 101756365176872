import React, { useEffect, useCallback, useState } from "react";
import "./counter.css";

const GeneratedValue = ({ turbineOn, timerValue, initMoneyValue }) => {
  const [moneyValue, setMoneyValue] = useState(initMoneyValue);
  const [counterOn, setCounterOn] = useState(true);

  const moneyCounter = useCallback(() => {
    setMoneyValue(moneyValue + 273);
  }, [moneyValue]);

  useEffect(() => {
    setCounterOn(true);
    if (turbineOn && timerValue > 0) {
      setTimeout(moneyCounter, 500);
    }
  }, [moneyValue, turbineOn, initMoneyValue]);

  useEffect(() => {
    if (timerValue === 0 && counterOn) {
      setMoneyValue(moneyValue - 250000);
      setCounterOn(!counterOn);
    }
  }, [moneyValue]);

  const numberWithSpaces = x => {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  };

  return (
    <div className="generatedvalue">
      <div className="generatedvalue-header">Value generated:</div>
      <div className="generatedvalue-amount">
        € {numberWithSpaces(moneyValue)}
      </div>
    </div>
  );
};

export default React.memo(GeneratedValue);
