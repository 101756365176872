import React from "react";
import "./slider.css";

const Slider = ({
  sliderValue,
  minSize,
  maxSize,
  onValueChange,
  onStartChange,
  timerValue
}) => {
  const handleChange = event => {
    onValueChange(event.target.value);
  };

  const handleStart = event => {
    onStartChange(event);
  };

  return (
    <div className="rangeslider">
      {timerValue === 0 ? (
        <button className="rangeslider--start" onClick={handleStart}>
          Start again
        </button>
      ) : (
        <div>
          <input
            className={
              "rangeslider__range " +
              (sliderValue >= 2000 || sliderValue <= -2000
                ? "rangeslider__range--critical"
                : null)
            }
            type="range"
            min={minSize}
            max={maxSize}
            step={100}
            value={sliderValue}
            onChange={handleChange}
          ></input>
          <div className="rangeslider__range--circle"></div>
          <div className="rangeslider__title">Forces on a screw</div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Slider);
