import React from "react";
import "./wind-turbine.css";
import { Tween } from "react-gsap";

function Blades({ failing, failed, turbineOn }) {
  const fadingClass = `fading ${failing ? "failing" : ""}`;
  const failedWingClass = `failedWing ${failed ? " failed" : ""}`;

  return (
    <g id="Blades">
      <g id="blade2">
        <path
          d="m4046.1 4064.7s735.54 407.81 1007.9 547.85c272.37 140.05 2006.3 1069 2006.3 1069s181.84 92.05 278.78 75.74c15.21-2.54 19.02-22.74 6.03-31.04-242.19-154.73-1980.8-1263.9-2301.3-1431.8-275.04-144.02-432.59-208.53-672.33-254.69-158.9-76.96-250.25-126.35-250.25-126.35l-75.12 151.31z"
          fill="#fff"
        />
        <path
          d="m5053.9 4612.7c272.32 140.13 2006.4 1069 2006.4 1069s181.83 92.05 278.78 75.74c11.41-1.96 16.42-13.85 12.76-23.12-97.09 15.77-278.09-75.92-278.09-75.92s-1734.2-928.85-2006.4-1068.9c-272.32-140.13-972.89-557.31-972.89-557.31l28.26-117.75-1.47-0.85-75.24 151.13c0.08 0.05 735.62 407.86 1007.9 547.99z"
          fill="#FFBDAB"
        />
        <path
          d="m4407 4159.8c5.97 3.45 12.44-3.56 8.52-9.17-11.04-16.07-25.18-38.79-37.22-65.13-8.22-18.15-9.77-31.28-8.07-40.81 0.48-2.84-0.92-5.61-3.53-6.89-73.45-35.59-132.11-65.19-173.62-86.49-3.13-1.58-6.78-0.46-8.53 2.58l-28.96 51.75c-1.75 3.03-0.63 6.91 2.4 8.66l249.01 145.5z"
          fill="#F2F2F2"
        />
        <path
          d="m5053.9 4612.7c272.32 140.13 2006.4 1069 2006.4 1069s181.83 92.05 278.78 75.74c11.41-1.96 16.42-13.85 12.76-23.12-97.09 15.77-278.09-75.92-278.09-75.92s-1734.2-928.85-2006.4-1068.9c-272.32-140.13-972.89-557.31-972.89-557.31l28.26-117.75-1.47-0.85-75.24 151.13c0.08 0.05 735.62 407.86 1007.9 547.99z"
          fill="#ccc"
        />
        <path
          d="m5051.6 4611.9c272.31 140.13 2006.4 1069 2006.4 1069s181.84 92.05 278.78 75.73c15.21-2.53 19.02-22.74 6.03-31.04-11.56-7.37-26.58-16.96-44.61-28.53-4.53-2.15-7.83-3.83-9.39-4.73-242.5-153.17-1934.6-1209.3-2255.5-1375.8-275.29-142.77-432.9-206.98-672.65-253.13-159.03-76.35-250.49-125.34-250.49-125.34l-65.29 126.5c32.17 17.88 740.62 410.39 1006.7 547.29z"
          fill="#fff"
          opacity=".5"
        />
        <path
          d="m4198.9 3957.6-77.75 134.67c-13.35 23.12-42.86 31.02-65.98 17.67l-30.66-17.7c-23.12-13.35-31.03-42.85-17.68-65.97l77.75-134.67c13.35-23.12 42.86-31.03 65.98-17.68l30.66 17.7c23.12 13.35 31.03 42.86 17.68 65.98z"
          fill="#F2F2F2"
        />
        <path
          d="m4198.2 3958.9-76.2 131.98c-8.45 14.64-27.17 19.65-41.8 11.2-14.64-8.45-19.65-27.16-11.2-41.8l76.2-131.98c8.45-14.64 27.16-19.65 41.8-11.2 14.63 8.45 19.65 27.16 11.2 41.8z"
          fill="#fff"
        />
      </g>

      <g id="blade3" className={failedWingClass}>
        <path
          d="m3989.6 3711.2s-14.6-840.9-29.5-1146.8-77.4-2272-77.4-2272-11.2-203.5-73.8-279.3c-9.8-11.9-29.2-5.0999-29.9 10.3-12.9 287.1-104.2 2347.4-89.3 2708.9 12.8 310.2 35.7 478.9 115.6 709.6 12.8 176.1 15.7 279.9 15.7 279.9l168.6-10.6z"
          fill="#fff"
        />
        <path
          d="m3960.2 2564.4c-14.8-305.9-77.4-2272.1-77.4-2272.1s-11.2-203.5-73.8-279.3c-7.4-8.9-20.2-7.3-26.4 0.5 62.2 76.2 73.3 278.8 73.3 278.8s62.7 1966.3 77.5 2272.1c14.8 305.9 3.8 1121.2 3.8 1121.2l-116.1 34.4v1.7l168.5-10.4c0-0.1-14.6-841-29.4-1146.9z"
          fill="#FFBDAB"
        />
        <path
          d="m3891.4 3351.1c0-6.9-9.3-9-12.2-2.8-8.4 17.6-21 41.2-37.8 64.8-11.6 16.2-22.2 24.1-31.3 27.4-2.7 1-4.4 3.6-4.2 6.5 5.9 81.4 9.6 147 11.9 193.6 0.2 3.5 3 6.1 6.5 6.1l59.3-0.8c3.5 0 6.3-2.9 6.3-6.4l1.5-288.4z"
          fill="#F2F2F2"
        />
        <path
          d="m3960.2 2564.4c-14.8-305.9-77.4-2272.1-77.4-2272.1s-11.2-203.5-73.8-279.3c-7.4-8.9-20.2-7.3-26.4 0.5 62.2 76.2 73.3 278.8 73.3 278.8s62.7 1966.3 77.5 2272.1c14.8 305.9 3.8 1121.2 3.8 1121.2l-116.1 34.4v1.7l168.5-10.4c0-0.1-14.6-841-29.4-1146.9z"
          fill="#ccc"
        />
        <path
          d="m3960.6 2566.9c-14.8-305.9-77.4-2272.1-77.4-2272.1s-11.2-203.5-73.8-279.3c-9.8-11.9-29.2-5.1002-29.9 10.3-0.6 13.7-1.4 31.5-2.4 52.9 0.4 5 0.6 8.7 0.6 10.5-11.4 286.6-80 2280.1-63.7 2641.2 14 309.8 37.2 478.4 117.1 709.1 13.4 175.9 16.7 279.6 16.7 279.6l142.2-6.7c-0.6-36.8-14.9-846.6-29.4-1145.5z"
          opacity=".5"
          className={fadingClass}
        />
        <path
          d="m3820.4 3632.4h155.5c26.7 0 48.3 21.6 48.3 48.3v35.4c0 26.7-21.6 48.3-48.3 48.3h-155.5c-26.7 0-48.3-21.6-48.3-48.3v-35.4c0-26.7 21.6-48.3 48.3-48.3z"
          fill="#F2F2F2"
        />
        <path
          d="m3821.8 3632.4h152.4c16.9 0 30.6 13.7 30.6 30.6s-13.7 30.6-30.6 30.6h-152.4c-16.9 0-30.6-13.7-30.6-30.6s13.7-30.6 30.6-30.6z"
          fill="#fff"
        />
        <path
          d="m3958.8 3616c0-9.9-8-17.9-17.9-17.9s-17.9 8-17.9 17.9v4h7v80.3h21.8v-80.3h7v-4z"
          opacity=".7"
          stroke="#999"
          strokeMiterlimit="10"
          strokeOpacity="0.29"
          strokeWidth="6"
          className={fadingClass}
        />
        <path
          d="m3888 3616c0-9.9-8-17.9-17.9-17.9s-17.9 8-17.9 17.9v4h7v80.3h21.8v-80.3h7v-4z"
          fill="#E6E6E6"
          opacity=".7"
          stroke="#999"
          strokeMiterlimit="10"
          strokeOpacity="0.29"
          strokeWidth="6"
        />
      </g>
      <g id="blade1">
        <path
          d="m3673 3908.6s-720.94 433.1-978.4 598.95c-257.47 165.86-1928.9 1203-1928.9 1203s-170.64 111.45-204.98 203.57c-5.405 14.43 10.184 27.83 23.87 20.74 255.09-132.38 2085-1083.5 2390.6-1277.1 262.24-166.19 396.89-270.37 556.73-454.92 146.11-99.13 234.55-153.54 234.55-153.54l-93.48-140.72z"
          fill="#fff"
        />
        <path
          d="m2694.5 4507.4c-257.52 165.76-1929 1203.1-1929 1203.1s-170.64 111.45-204.98 203.56c-4.007 10.86 3.778 21.14 13.633 22.61 34.891-91.97 204.8-202.88 204.8-202.88s1671.5-1037.4 1929-1203.2c257.51-165.77 969.08-563.9 969.08-563.9l87.84 83.35 1.48-0.85-93.26-140.73c-0.09 0.05-721.03 433.15-978.54 598.92z"
          fill="#FFBDAB"
        />
        <path
          d="m3410.2 4173.7c-5.98 3.45-3.15 12.55 3.67 11.96 19.44-1.52 46.18-2.41 75.02 0.34 19.83 1.95 31.97 7.18 39.38 13.41 2.22 1.83 5.32 2.01 7.73 0.38 67.54-45.81 122.51-81.81 161.71-107.1 2.93-1.93 3.78-5.65 2.03-8.68l-30.34-50.96c-1.75-3.03-5.66-4-8.69-2.25l-250.51 142.9z"
          fill="#F2F2F2"
        />
        <path
          d="m2694.5 4507.4c-257.52 165.76-1929 1203.1-1929 1203.1s-170.64 111.45-204.98 203.56c-4.007 10.86 3.778 21.14 13.633 22.61 34.891-91.97 204.8-202.88 204.8-202.88s1671.5-1037.4 1929-1203.2c257.51-165.77 969.08-563.9 969.08-563.9l87.84 83.35 1.48-0.85-93.26-140.73c-0.09 0.05-721.03 433.15-978.54 598.92z"
          fill="#ccc"
        />
        <path
          d="m2696.4 4505.8c-257.52 165.77-1929 1203.1-1929 1203.1s-170.64 111.45-204.98 203.57c-5.405 14.43 10.184 27.83 23.87 20.74 12.165-6.33 27.98-14.54 47.013-24.37 4.13-2.85 7.235-4.87 8.794-5.77 253.9-133.43 2014.6-1070.8 2319.2-1265.4 261.3-167.02 395.71-271.41 555.55-455.96 145.63-99.55 233.79-154.26 233.79-154.26l-76.9-119.8c-31.57 18.92-725.73 436.21-977.33 598.21z"
          fill="#fff"
          opacity=".5"
        />
        <path
          d="m3689.3 4094.5-77.75-134.67c-13.35-23.12-5.44-52.63 17.68-65.98l30.66-17.7c23.12-13.35 52.63-5.44 65.98 17.68l77.75 134.67c13.35 23.12 5.44 52.63-17.68 65.98l-30.66 17.7c-23.12 13.35-52.63 5.44-65.98-17.68z"
          fill="#F2F2F2"
        />
        <path
          d="m3688.6 4093.2-76.2-131.99c-8.45-14.63-3.43-33.35 11.2-41.8 14.64-8.45 33.35-3.43 41.8 11.2l76.2 131.99c8.45 14.63 3.44 33.35-11.2 41.8-14.63 8.45-33.35 3.43-41.8-11.2z"
          fill="#fff"
        />
      </g>
    </g>
  );
}

function WindTurbine({ turbineOn, timerValue, critical }) {
  return (
    <div className="turbine">
      <svg viewBox="0 0 7807 7807">
        <g id="Pole">
          <path
            d="m4010 7492h-204c-1.1 0-2-2.65-2-5.89v-3283.2c0-3.24 0.9-5.89 2-5.89h204c1.1 0 2 2.65 2 5.89v3282.9c0 3.53-0.9 6.18-2 6.18z"
            fill="#E6E6E6"
          />
          <path
            d="m3990.2 4218.9h-143.9c-0.1 0-0.2 0-0.3 0.1-0.1 0-0.2-0.1-0.4-0.1h-20.4c-11.7 0-21.3 9.6-21.3 21.3v3230.5c0 11.7 9.6 21.3 21.3 21.3s21.3-9.6 21.3-21.3v-3076.8c0-33.8 27.4-61.2 61.2-61.2s61.2 27.4 61.2 61.2v3076.8c0 11.7 9.6 21.3 21.3 21.3s21.3-9.6 21.3-21.3v-3230.4c0-11.8-9.6-21.4-21.3-21.4z"
            fill="#B3B3B3"
          />
          <path
            d="m4001.6 4270.3h-196.4c-32 0-58.2-26.2-58.2-58.2v-231.8c0-32 26.2-58.2 58.2-58.2h196.4c32 0 58.2 26.2 58.2 58.2v231.8c0 32-26.2 58.2-58.2 58.2z"
            fill="#B3B3B3"
          />
          <path
            d="m3992.8 4249.3h-178.8c-30.9 0-56.2-25.3-56.2-56.2v-235.9c0-30.9 25.3-56.2 56.2-56.2h178.9c30.9 0 56.2 25.3 56.2 56.2v235.9c-0.1 30.9-25.4 56.2-56.3 56.2z"
            fill="#E6E6E6"
          />
          <path
            d="m3992.8 3901h-178.8c-30.9 0-56.2 25.3-56.2 56.2v192c39.4 33 90.2 52.9 145.6 52.9s106.2-19.9 145.6-52.9v-192c0-30.9-25.3-56.2-56.2-56.2z"
            fill="#B3B3B3"
            opacity=".6"
          />
        </g>
      </svg>
      <Tween
        from={{
          transformOrigin: "50% 50%",
          rotation: -360,
          repeat: -1,
          ease: "Linear.easeNone"
        }}
        duration={16}
        playState={turbineOn === false || timerValue === 0 ? "pause" : "play"}
      >
        <svg viewBox="0 0 7807 7807" style={{ top: 0 }}>
          <Blades
            turbineOn={turbineOn === true && timerValue > 0}
            failing={critical}
            failed={timerValue <= 0}
          />
        </svg>
      </Tween>
      <svg viewBox="0 0 7807 7807">
        <g id="Innercircle">
          <path
            d="m3903.1 4130.2c125.42 0 227.1-101.68 227.1-227.1s-101.68-227.1-227.1-227.1-227.1 101.68-227.1 227.1 101.68 227.1 227.1 227.1z"
            fill="#ccc"
          />
          <path
            d="m3903.1 3903.1 0.1 0.2c0-0.1 0-0.2-0.1-0.2z"
            fill="#999"
            opacity=".18"
          />
          <path
            d="m3778.6 4078.4c35.1 25 78.1 39.7 124.5 39.7 46.8 0 90.2-15 125.5-40.4l-125.4-174.4c0.1 4.2-68.2 98.3-124.6 175.1z"
            fill="#999"
            opacity=".18"
          />
          <path
            d="m3903.1 4118.1c118.74 0 215-96.26 215-215s-96.26-215-215-215-215 96.26-215 215 96.26 215 215 215z"
            fill="#fff"
          />
          <path
            d="m3700.3 3832c-7.9 22.4-12.2 46-12.2 71.2 0 118.7 96.3 215 215 215s215-96.3 215-215c0-24.9-4.2-48.9-12.1-71.2l-203 71.2-202.7-71.2z"
            fill="#F2F2F2"
          />
          <path
            d="m3903.1 3963.7c33.47 0 60.6-27.13 60.6-60.6s-27.13-60.6-60.6-60.6-60.6 27.13-60.6 60.6 27.13 60.6 60.6 60.6z"
            fill="#fff"
          />
        </g>
      </svg>
    </div>
  );
}
export default React.memo(WindTurbine);
