import React, { PureComponent } from "react";
import "./animation.css";

class Bird extends PureComponent {
  render() {
    return (
      <div className="wrapper__bird">
        <svg viewBox="0 0 347 262" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m108.5 165c1.4-19 23.3-59.3 23.3-59.3l141.5-105.1-80.6 131.5s18.2 92.6-47.4 93.1c-35 0.3-38.5-37.5-36.8-60.2z"
            fill="#4D4D4D"
          />
          <path
            d="m319.9 219.1s-61.2-17.2-101.8-28.8c-51.4-14.7-70.2-23.4-87.8-29.3-15.8-5.3-24.2-8.8-41.8-10.9-16.1-1.9-38.9 0.8-52.2 17.6-8.8 10.9-7.9 24.7-7.9 24.7s4.2 20.5 25.9 38.5c15.4 12.7 41.4 28.6 73.6 30.5 43 2.5 98.6-7.1 192-42.3z"
            fill="#4D4D4D"
          />
          <path
            d="m0.69995 206.9 27.6-17.9 16.3 10.5-4.5 15.7-39.4-8.3z"
            fill="#F93C2D"
          />
          <path
            d="m129.4 154.9c15.1-26 47.7-49.4 47.7-49.4l169.9-42.1-123 93.1s-14.1 84.2-78 69.4c-32.5-7.4-28-51.4-16.6-71z"
            fill="#ccc"
          />
          <path
            d="m51.8 188.1c3.4794 0 6.3-2.821 6.3-6.3s-2.8206-6.3-6.3-6.3c-3.4793 0-6.3 2.821-6.3 6.3s2.8207 6.3 6.3 6.3z"
            fill="#0E0C44"
          />
        </svg>
      </div>
    );
  }
}

export default Bird;
