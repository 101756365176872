import React, { Component } from "react";
import "./App.css";

import Cloud from "./components/Background/Cloud";
import Bird from "./components/Background/Bird";
import Ufo from "./components/Background/Ufo";
import Logo from "./components/Background/Logo";

import WindTurbine from "./components/MainView/WindTurbine/WindTurbine";
import RadarChart from "./components/MobileView/Charts/RadarChart";
import LineChart from "./components/MobileView/Charts/LineChart";

import Slider from "./components/MainView/Slider/Slider";
import Countdown from "./components/MainView/Counters/Countdown";
import ScrewStatus from "./components/MobileView/ScrewStatus/ScrewStatus";
import GeneratedValue from "./components/MainView/Counters/GeneratedValue";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      turbineOn: true,
      sliderValue: 600,
      timerValue: 10,
      timerOn: false,
      initMoneyValue: 2250000,
      criticalValue: 2000,
      idleValue: 500
    };

    this.handleSliderValueChange = this.handleSliderValueChange.bind(this);
    this.handleTurbineOnChange = this.handleTurbineOnChange.bind(this);
    this.handleStartValueChange = this.handleStartValueChange.bind(this);
    this.handleTimerStatus = this.handleTimerStatus.bind(this);
    this.startTimer = this.startTimer.bind(this);
  }

  handleStartValueChange() {
    this.setState({
      sliderValue: 0,
      timerValue: 10,
      turbineOn: true,
      timerOn: false,
      initMoneyValue: 2250000
    });
  }

  startTimer() {
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      if (this.state.timerOn === true && this.state.timerValue > 0) {
        this.setState({
          timerValue: this.state.timerValue - 1
        });
      } else if (this.state.timerValue === 0) {
        this.setState({ turbineOn: false, timerOn: false });
      }
    }, 2000);
  }

  handleSliderValueChange(sliderValue) {
    this.setState({
      sliderValue
    });
    this.handleTimerStatus(sliderValue);
  }

  handleTimerStatus(sliderValue) {
    if (
      (sliderValue >= this.state.criticalValue ||
        sliderValue <= -this.state.criticalValue) &&
      this.state.turbineOn === true
    ) {
      if (!this.state.timerOn) {
        this.setState({
          timerOn: true
        });
        this.startTimer();
      }
    } else if (
      sliderValue <= this.state.criticalValue ||
      sliderValue >= -this.state.criticalValue
    ) {
      this.setState({
        timerOn: false
      });
    }
  }

  handleTurbineOnChange() {
    this.setState({
      turbineOn: !this.state.turbineOn
    });
    if (
      this.state.sliderValue <= -this.state.criticalValue ||
      this.state.sliderValue >= this.state.criticalValue
    ) {
      this.setState({
        timerOn: !this.state.timerOn
      });
    }
    this.startTimer();
  }

  render() {
    return (
      <div className="bg">
        <Cloud />
        <Bird />
        <Ufo />
        <div className="wrapper">
          <div className="wrapper__main">
            <GeneratedValue
              initMoneyValue={this.state.initMoneyValue}
              turbineOn={this.state.turbineOn}
              timerValue={this.state.timerValue}
            />
            <Countdown
              sliderValue={this.state.sliderValue}
              criticalValue={this.state.criticalValue}
              timerValue={this.state.timerValue}
              turbineOn={this.state.turbineOn}
            />
            <WindTurbine
              turbineOn={this.state.turbineOn}
              timerValue={this.state.timerValue}
              critical={
                this.state.sliderValue >= this.state.criticalValue ||
                this.state.sliderValue <= -this.state.criticalValue
              }
            />
            <Logo />
            <Slider
              onValueChange={this.handleSliderValueChange}
              onStartChange={this.handleStartValueChange}
              sliderValue={this.state.sliderValue}
              minSize={-2500}
              maxSize={2500}
              timerValue={this.state.timerValue}
            />
          </div>
          <div className="wrapper__mobile">
            <div className="mobile--inner">
              <RadarChart
                turbineOn={this.state.turbineOn}
                sliderValue={this.state.sliderValue}
                criticalValue={this.state.criticalValue}
                timerValue={this.state.timerValue}
                critical={
                  this.state.sliderValue >= this.state.criticalValue ||
                  this.state.sliderValue <= -this.state.criticalValue
                }
              />
              <div className="mobile--footer">
                <LineChart
                  sliderValue={this.state.sliderValue}
                  turbineOn={this.state.turbineOn}
                  timerValue={this.state.timerValue}
                  critical={
                    this.state.sliderValue >= this.state.criticalValue ||
                    this.state.sliderValue <= -this.state.criticalValue
                  }
                />
                <ScrewStatus
                  onClickChange={this.handleTurbineOnChange}
                  turbineOn={this.state.turbineOn}
                  sliderValue={this.state.sliderValue}
                  criticalValue={this.state.criticalValue}
                  timerValue={this.state.timerValue}
                  timerOn={this.state.timerOn}
                />
              </div>
              <div
                className={`mobile--idle${
                  this.state.sliderValue >= this.state.idleValue ||
                  this.state.sliderValue <= -this.state.idleValue
                    ? " none"
                    : ""
                }`}
              >
                <h1>Live Bolt Monitoring</h1>
                <p>Sensorise Smart Screw System S4</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
